import React, { useCallback, useEffect, useRef, useState } from 'react';
import { constantStyles } from '../constants/constantStyles';
import { RiArrowUpSFill } from "react-icons/ri";
import { FaRegSquare, FaRegSquareCheck } from "react-icons/fa6";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";


const optionsIconSize = 20;
const optionsYMargin = 10

const Option = ({ option, checked, setChecked, addFilter, removeFilter}) => {

    const { name, hidden } = option;
    return (<div style={{
        ...styles.filterOption,
        cursor: !hidden ? "pointer" : 'default',
        textDecoration: hidden ? "line-through" : 'auto',
    }} key={option} onClick={!hidden ? () => {
        setChecked(!checked);
        if (!checked) {
            addFilter(option);
        } else {
            removeFilter(option);
        }
    } : null}>
        <div>
            {checked ? <FaRegSquareCheck /> : <FaRegSquare />}
        </div>
        <div style={styles.filterOptionText}>{name}</div>
    </div>);
}

const countChildren = (filter) => {
    if (!filter.children || filter.children.length === 0) {
        return 0; // No children
    }
    return filter.children.reduce((count, child) => count + 1 + countChildren(child), 0);
};

const Dropdown = ({ filter, topLevel, parentCheck, addFilter, removeFilter, clearChecks, startingParams }) => {

    const [isOpen, setIsOpen] = useState(true);
    const totalChildren = filter.children ? countChildren(filter) : 1;
    const maxHeight = `${totalChildren * (optionsIconSize + optionsYMargin)}px`;
    const {filter_type, id} = filter;
    console.log('filter', filter);
    console.log('startingParams', startingParams);
    console.log(filter_type in startingParams && startingParams[filter_type].includes(id));
    const [checked, setChecked] = useState(id && filter_type in startingParams && startingParams[filter_type].includes(id));
    const hasChildren = filter.children != null && filter.children.length > 0;
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setChecked(parentCheck);

    }, [parentCheck]);

    useEffect(() => {
        if (clearChecks && filter.filter_type in clearChecks) {
            const idsToCheck = clearChecks[filter.filter_type];
            if (idsToCheck.includes(filter.id)) {
                setChecked(false);
            }
        }
    }, [clearChecks]);

    return (
        <div style={topLevel ? styles.filterContainer : {}} key={filter.name}>

            {hasChildren
                ?
                <div>
                    <div style={topLevel ?
                        { ...styles.filterHeader, ...styles.topLevelHeader }
                        :
                        styles.filterHeader
                    } onClick={() => { setIsOpen(!isOpen) }}>
                        <div style={styles.checkboxTitleContainer}>
                            {!topLevel && <div onClick={(e) => {
                                e.stopPropagation();
                                setChecked(!checked);
                                if (!checked) {
                                    addFilter(filter);
                                } else {
                                    removeFilter(filter);
                                }
                            }
                            }>
                                {checked ? <FaRegSquareCheck /> : <FaRegSquare />}
                            </div>}
                            <div style={!topLevel ? styles.filterOptionText : {}}>{filter.name}</div>
                        </div >
                        <RiArrowUpSFill
                            style={{
                                transition: "transform 0.3s ease-in-out",
                                transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
                            }} />
                    </div>
                    <div style={{
                        ...styles.optionsContainer,
                        maxHeight: isOpen ? maxHeight : '0',
                    }}>
                        <div>
                            {filter.children.map(child => (
                                <Dropdown key={child.id} filter={child} topLevel={false} parentCheck={checked} addFilter={addFilter} removeFilter={removeFilter} clearChecks={clearChecks} startingParams={startingParams}/>
                            ))}
                        </div>
                    </div>
                </div>

                : <Option option={filter} checked={checked} setChecked={setChecked} addFilter={addFilter} removeFilter={removeFilter} />}
        </div>
    );
};

const PriceRange = ({ priceRange, setFilteredPrice, filteredPrice, startingParams}) => {

    const min_price = Math.floor(Number(priceRange.min_price ?? 0));
    const max_price = Math.ceil(Number(priceRange.max_price ?? 200));
    const [isOpen, setIsOpen] = useState(true);
    const maxHeight = `${1 * (optionsIconSize + optionsYMargin)}px`;
    const [range, setRange] = useState([filteredPrice.min_price ?? min_price, filteredPrice.max_price ?? max_price]);
    const [tooltipPositions, setTooltipPositions] = useState([0, 0]);

    useEffect(() => {

        let new_min = min_price;
        let new_max = max_price;

        if (filteredPrice.min_price != null && filteredPrice.min_price >= min_price && filteredPrice.min_price <= max_price) {
            new_min = filteredPrice.min_price;
        }
        if (filteredPrice.max_price != null && filteredPrice.max_price >= min_price && filteredPrice.max_price <= max_price) {
            new_max = filteredPrice.max_price;
        }

        setRange([new_min, new_max]);

    }, [min_price, max_price]);

    useEffect(() => {

        const element = document.querySelector(`.rc-slider`);
        if (element != null) {
            const width = element.offsetWidth;
            const positions = range.map((value) => {
                let res = (value - min_price) / (max_price - min_price) * width;
                if (res <= 99) {
                    res += 10;
                } else if (res <= 999) {
                    res += 7;
                }
                return res;
            });
            setTooltipPositions(positions);
        }

    }, [range, min_price, max_price]);

    return <div style={styles.priceComponentContainer} >
        <div style={{ ...styles.filterHeader, ...styles.topLevelHeader }}
            onClick={() => { setIsOpen(!isOpen) }}>
            <div style={styles.checkboxTitleContainer}>
                <div>{'Price'}</div>
            </div >
            <RiArrowUpSFill
                style={{
                    transition: "transform 0.3s ease-in-out",
                    transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
                }} />
        </div>
        <div style={{
            ...styles.priceContainer,
            maxHeight: isOpen ? maxHeight : '0',
        }}>
            <div style={styles.priceRangeContainer}>
                <Slider range min={min_price} max={max_price} value={range} onChange={setRange} step={5}
                    onChangeComplete={(newRange) => setFilteredPrice({ min_price: newRange[0], max_price: newRange[1] })} />
                {isOpen && <div style={{ position: 'absolute', left: tooltipPositions[0] + 'px', transform: 'translateY(50%)' }}>{range[0]}</div>}
                {isOpen && <div style={{ position: 'absolute', left: tooltipPositions[1] + 'px', transform: 'translateY(50%)' }}>{range[1]}</div>}
            </div>
        </div>
    </div>;

}

const Filters = ({ availableCategories, setSearchFilters, priceRange, setFilteredPrice, filteredPrice, availableBrands, showGenders, startingParams }) => {
    // return a available_filters map with the products query, 
    // should include each sections open options r
    // ex: which brands are avaible given the products searched for

    const [clearChecks, setClearChecks] = useState({});

    const addFilter = useCallback((filter) => {

        const { filter_type } = filter;
        const idList = getAllIds(filter, []);

        setSearchFilters((prev) => ({
            ...prev,
            [filter_type]: prev[filter_type] ? [...prev[filter_type], ...idList] : [...idList],
        }));

    }, [setSearchFilters]);

    const removeFilter = useCallback((filter) => {

        const { filter_type, id } = filter;
        const idList = getAllIds(filter, []);

        setSearchFilters((prev) => {
            const updatedArray = (prev[filter_type] || []).filter(item => !idList.includes(item));
            if (updatedArray.length === 0) {
                const newFilters = { ...prev };
                delete newFilters[filter_type];
                return newFilters;
            }
            return { ...prev, [filter_type]: updatedArray };
        });

    }, [setSearchFilters])

    const addGender = useCallback((gender) => {
        const { filter_type, id } = gender;

        const removeGender = id.toLowerCase() == 'men' ? 'women' : 'men';

        const categoriesWithGender = getIdsByGender(availableCategories, removeGender);
        const brandsWithGender = getIdsByGender(availableBrands, removeGender);

        setClearChecks({ categories: categoriesWithGender, brands: brandsWithGender });

        setSearchFilters((prev) => {
            // adding a gender will change what brands/categories are available, 
            // remove them to avoid conflicts with having a category/brand selected 
            // that is about to become unavailable
            const newValues = { ...prev };
            if (prev.categories) {
                const newCategories = prev.categories.filter(id => !categoriesWithGender.includes(id));
                newValues.categories = newCategories;
            }

            if (prev.brands) {
                const newBrands = prev.brands.filter(id => !brandsWithGender.includes(id));
                newValues.brands = newBrands;
            }

            const noGendersSet = prev[filter_type] == null || prev[filter_type].length === 0;
            if (noGendersSet) {
                return { ...newValues, [filter_type]: [id] };
            }
            return ({ ...newValues, [filter_type]: [...prev[filter_type], id] });
        })
    }, [setSearchFilters, availableCategories, availableBrands]);

    return (
        <div style={styles.filtersContainer}>
            {showGenders && availableCategories != null && availableCategories.length > 0 && genderTree.map(gender => <Dropdown key={0} filter={gender} topLevel={true} addFilter={addGender} removeFilter={removeFilter} startingParams={startingParams}/>)}
            {availableCategories != null && availableCategories.length > 0 && availableCategories.map(filter => <Dropdown key={1} filter={filter} topLevel={true} addFilter={addFilter} removeFilter={removeFilter} clearChecks={clearChecks} startingParams={startingParams}/>)}
            {availableBrands != null && availableBrands.length > 0 && availableBrands.map(brand => <Dropdown key={2} filter={brand} topLevel={true} addFilter={addFilter} removeFilter={removeFilter} clearChecks={clearChecks} startingParams={startingParams}/>)}
            {availableCategories != null && availableCategories.length > 0 && priceRange.min_price && priceRange.max_price && <PriceRange key={3} priceRange={priceRange} setFilteredPrice={setFilteredPrice} filteredPrice={filteredPrice} startingParams={startingParams}/>}
        </div>
    );
};

const genderTree = [{
    "filter_type": "gender",
    "id": null,
    "name": "Gender",
    "description": null,
    "parent_id": null,
    "children": [
        {
            "id": "Men",
            "name": "Men",
            "parent_id": null,
            "filter_type": "gender",
            "children": []
        },
        {
            "id": "Women",
            "name": "Women",
            "parent_id": null,
            "filter_type": "gender",
            "children": []
        }
    ]
}
];

function getIdsByGender(data, targetGender) {

    let result = [];

    function traverse(items) {
        for (const item of items) {
            if (item.gender && item.gender.toLowerCase() === targetGender) {
                result.push(item.id);
            }
            if (item.children && item.children.length > 0) {
                traverse(item.children);
            }
        }
    }

    traverse(data);
    return result;
}

const getAllIds = (filter, idList) => {

    if (filter === null) { return; }

    idList.push(filter.id);

    for (let i = 0; i < filter.children.length; i++) {
        getAllIds(filter.children[i], idList)
    }

    return idList
}

export default Filters;

const styles = {
    filtersContainer: {
        display: 'block',
        height: 'max-content',
        width: '100%',
        border: '1px solid',
        borderLeft: '0px',
        padding: '10px',
        fontFamily: constantStyles.mainFontFamily,
        flexGrow: 1,
        minWidth: '250px',
        maxWidth: '250px',
    },
    filterContainer: {
        display: 'block',
        height: 'auto',
        width: '100%',
        margin: '0 0 20px 0',
    },
    optionsContainer: {
        marginLeft: '15px',
        overflow: 'hidden',
        transition: 'max-height 0.3s ease-in-out',
    },
    priceContainer: {
        overflow: 'hidden',
        transition: 'max-height 0.3s ease-in-out',
    },
    priceComponentContainer: {
        minHeight: '70px',
    },
    topLevelHeader: {
    },
    filterHeader: {
        cursor: "pointer",
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
    },
    checkboxTitleContainer: {
        display: 'flex',
    },
    priceRangeContainer: {
        margin: '5px 10px',
    },
    filterOption: {
        display: 'flex',
        margin: optionsYMargin + 'px 0 ' + optionsYMargin + 'px 0',
    },
    filterOptionText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        opacity: '0.6',
        marginLeft: '10px',
    },
};