import React from 'react';
import Header from '../header/header';
import { constantStyles } from '../constants/constantStyles';

const Home = () => {
    return (
        <div>
            <Header />
            <div style={styles.mainContainer}>
                <div style={styles.imageContainer} onClick={() => window.location.href = '/featured'}>
                    <img style={styles.image} src='/images/home_page/summer.png' alt="featured summer styles image" />
                    <div style={styles.imageText}>Trending Summer Styles</div>
                </div>
                <div style={styles.imageContainer} onClick={() => window.location.href = '/men?categories=20'}>
                    <img style={styles.image} src='/images/home_page/city_men.jpg' alt="featured men styles image" />
                    <div style={styles.imageText}>Shop Men's Outerwear</div>
                </div>
            </div>
        </div>
    );
};

export default Home;

const styles = {
    mainContainer: {
        display: 'flex',
        gap: '2%',
        margin: '20px auto',
        justifyContent: 'center',
    },
    imageContainer: {
        cursor: 'pointer',
    },
    image: {
        width: '100%',
    },
    imageText: {
        margin: '5px 0',
        fontFamily: constantStyles.mainFontFamily,
        fontSize: '18px',
    },
};