import React from 'react';
import { Link } from "react-router-dom";
import { constantStyles } from '../constants/constantStyles';

const Navbar = () => {
    return (
        <>
            <nav  style={styles.navHolder}>
                <ul style={styles.navList}>
                    <li style={styles.li}><Link style={styles.link} to="/featured">Featured</Link></li>
                    {/* <li style={styles.li}><Link style={styles.link} to="/new">New</Link></li> */}
                    <li style={styles.li}><Link style={styles.link} to="/women">Women</Link></li>
                    <li style={styles.li}><Link style={styles.link} to="/men">Men</Link></li>
                    {/* <li style={styles.li}><Link style={styles.link} to="/insights">Insights</Link></li>
                    <li style={styles.li}><Link style={styles.link} to="/brands">Brands</Link></li> */}
                    <li style={styles.li}><Link style={styles.link} to="/likes">Likes</Link></li>
                </ul>
            </nav>
        </>
    );
};

export default Navbar;
const styles = {
    navHolder: {
        borderTop: '1px',
        borderBottom: '1px',
        borderLeft: '0px',
        borderRight: '0px',
        borderColor: constantStyles.mainDarkGreen,
        borderStyle: 'solid',
    },
    navList: {
        display: 'flex',
        justifyContent: 'space-between',
        listStyle: 'none',
        margin: constantStyles.outermostMargin,
        padding: '16px 16px',
    },
    li: {
    },
    link: {
        textDecoration: 'none',
        fontSize: '18px',
        fontFamily: constantStyles.mainFontFamily,
        color: constantStyles.mainDarkGreen,
    }
};