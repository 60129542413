import React, { useEffect } from 'react';
import '../styles/about.css';
import Header from '../header/header';


const About = () => {

    useEffect(() => {
        // Check if there's a hash in the URL
        const hash = window.location.hash;

        if (hash) {
            // Scroll to the element with the corresponding ID
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return <div>
        <Header />
        <div className="aboutComponent">
            <div className='content'>
                <ul className='information'>
                    <li id='about'>
                        <span id='about-start'>About Us</span>
                        <hr></hr>
                        <p style={{ lineHeight: 2 }}>Finding clothes fitted to your unique style can be difficult. In todays society, the internet and social media are cluttered with tons of clothing brands making it hard for the extraordinary ones to standout. The Sidewalk was designed to help users find and compare these great brands, making it easy to discover clothing suited perfectly for them. When using The Sidewalk you can easily find your specific style and compare a variety of brands and price ranges. The Sidewalk is affiliated with an assortment of high quality brands as well as up-and-coming brands that many have not yet heard of. We display their products here to help further grow their audiences.</p>
                    </li>
                    <li id='contact-us'>
                        <span id='contact-start'>Contact Us</span>
                        <hr></hr>
                        <p>For any general inquiries please send an email to info@thesidewalk.co.</p>
                        <p>If you work with a brand and would like your products featured on The Sidewalk please send an email to affiliate@thesidewalk.co.</p>
                        <p>If you are an influencer and would like to work with The Sidewalk please send an email to affiliate@thesidewalk.co.</p>
                    </li>
                    <li id='privacy'>
                        <span id='privacy-start'>Privacy Policy</span>
                        <hr></hr>
                        <p>Guarding the privacy and safety of your data is very important to us. This Policy outlines what we collect about you, and how we use, share, and manage this data. It also outlines the choices you have about this data. Please read it carefully. If you have any questions or concerns about this privacy policy, or our practices with regards to your personal information, please contact us at info@thesidewalk.co.</p>
                        <span id='smaller'>What We Collect and Why</span>
                        <p id='bottom'>We collect your data in several ways including when you choose to share it with us, when you engage with our digital properties, and from our affiliates and partners. The table below describes what we collect, how we collect it and why.</p>
                        <DataTable dataType={'usage'} />
                        <span id='smaller'>How We Share Your Data</span>
                        <p>{"We do not transfer your information to third parties in exchange for money and we will not do so. However, we do transfer personal information to certain third parties in order to operate our business (for example, to market our products and services)."}</p>
                        <p>We may share your data with our affiliates, service providers, certain third parties, and as necessary for legal requirements.</p>
                        <p>Service providers. Service providers are partners who have agreed to use your data only for the purpose of providing the services in our contract and who have agreed not to “sell” your data. We may share your data with our service providers as described below.</p>
                        <p>Third parties. Third parties are unaffiliated companies that have not agreed to limit their use of your data only to provide the services in our contract and/or have not agreed not to “sell” your data. For example, they may need to use the data to provide you services directly that go beyond our contract with them (e.g. your credit card company). We share your data with “third parties” only as allowed by applicable law.</p>
                        <p> “Personal” vs. Anonymized Information. We collect and maintain both “personal” and anonymized data. “Personal” data is information that identifies you or could reasonably be associated with or linked to you. Anonymized information is data that is not linked and could not reasonably be linked to you.</p>
                        <p id='bottom'>The table below describes how we share your information</p>
                        <DataTable dataType={'sharing'} />

                    </li>
                    <li id='terms'>
                        <span id="terms-start">
                            Terms of Use
                        </span>
                        <hr />
                        <p>
                            Welcome to the The Sidewalk Web Site (the "Site"). The Sidewalk provides this Site as a service to its users. Please review the following basic rules that govern your use of our Site (the "Agreement"). Please note that your use of our Site constitutes your agreement to follow and be bound by these terms. If you do not agree to these terms, please do not use this Site.
                        </p>
                        <p id="bottom">
                            Although you may "bookmark" a particular portion of this Site and thereby bypass this Agreement, your use of this Site still binds you to the terms. The Sidewalk reserves the right to update or modify these Terms and Conditions at any time without prior notice. Your use of the The Sidewalk website following any such changes constitutes your agreement to follow and be bound by the Terms and Conditions as changed. For this reason, we encourage you to review these Terms and Conditions whenever you use this web site.
                        </p>
                        <span id="smaller">
                            Usage Restrictions
                        </span>
                        <p>
                            All of the content you see and hear on the Site, including, for example, all of the page headers, images, illustrations, graphics, audio clips, video clips, and text are subject to trademark, service mark, trade dress, copyright and/or other intellectual property rights or licenses held by The Sidewalk, one of its affiliates or by third parties who have licensed their materials to The Sidewalk. The entire content of the Site is copyrighted as a collective work under U.S. copyright laws, and coordination, arrangement and enhancement of the content.
                        </p>
                        <p>
                            The content of the Site is intended solely for the personal, noncommercial use by the users of our site. You may download, print or store selected portions of the content, provided you (1) only use these materials for your own personal, noncommercial use, (2) do not copy or post the content on any network computer or broadcast the content in any media, and (3) do not modify or alter the content in any way, or delete or change any copyright or trademark notice.
                        </p>
                        <p>
                            No right, title or interest in any content or materials is transferred to you as a result of any such activities. The Sidewalk reserves complete title and full intellectual property rights in any content you download, reproduce, print, redistribute or store from this website.
                        </p>
                        <p>
                            DMCA Notice &amp; Take-down Procedure Without limiting the foregoing, if you believe that your work has been copied and posted on the Site in a way that constitutes copyright infringement, please provide The Sidewalk with the following information: (i) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; (ii) a description of the copyrighted work that you claim has been infringed; (iii) a description of where the material that you claim is infringing is located on the Site; (iv) your address, telephone number, and e-mail address; (v) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law, including the law of fair use; (vi) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf. To contact The Sidewalk about DMCA related issues please e-mail dmca_notice@thesidewalk.co.
                        </p>
                        <p>
                            IMPORTANT! PLEASE NOTE:
                        </p>
                        <p>
                            YOUR E-MAIL MUST BE WRITTEN IN ENGLISH AND MUST HAVE THE FOLLOWING SUBJECT LINE:
                        </p>
                        <p>
                            “DMCA NOTICE OF INFRINGEMENT”
                        </p>
                        <p id="bottom">
                            IF YOUR E-MAIL DOES NOT HAVE THIS SUBJECT LINE AND/OR IS NOT WRITTEN IN ENGLISH, YOUR E-MAIL WILL NOT BE READ.
                        </p>
                        <span id="smaller">
                            Pricing Policy
                        </span>
                        <p id="bottom">
                            At The Sidewalk, our commitment is to offer convenience, service, and product availability online at accurate prices every day. While the products we display are updated frequently, we cannot guarantee that the product prices we display will always match the products price on our affiliate brands website. The prices are set by our affiliates and their prices are what is accepted and recognized. If you find an inaccurate price displayed on the The Sidewalk website you can let us know by emailing info@thesidewalk.co. We will do our best to resolve the issue.
                        </p>
                        <span id="smaller">
                            Colors
                        </span>
                        <p id="bottom">
                            We have done our best to display as accurately as possible the colors of the products shown on the Site. However, because the colors you see will depend on your monitor, we cannot guarantee that your monitor's display of any color will be accurate.
                        </p>
                        <span id="smaller">
                            Correction of Errors and Inaccuracies
                        </span>
                        <p id="bottom">
                            The information of the Site may contain typographical errors or inaccuracies and may not be complete or current. We therefore reserve the right to correct any errors, inaccuracies or omissions and to change and update information at any time without prior notice. We apologize for any inconvenience this may cause you.
                        </p>
                        <span id="smaller">
                            Term: Termination
                        </span>
                        <p id="bottom">
                            These terms and conditions are applicable to you upon your accessing the Site. These terms and conditions, or any of them, may be terminated by The Sidewalk without notice at any time for any reason.
                        </p>
                        <span id="smaller">
                            Notice
                        </span>
                        <p id="bottom">
                            The Sidewalk may deliver notice to you by means of electronic mail or a general notice on the site.
                        </p>
                        <span id="smaller">
                            Use of Site
                        </span>
                        <p id="bottom">
                            Harassment in any manner or form on the site, including via e-mail and chat or by obscene or abusive language is strictly forbidden. Impersonation of others, including a The Sidewalk or other licensed employee, host, or representative as well as other members or visitors on the site is prohibited. You may not upload to, distribute, or otherwise publish through this site any content which is libelous, defamatory, obscene, threatening, invasive of privacy or publicity rights, abusive, illegal, or otherwise objectionable which may constitute or encourage a criminal offense, violate the rights of any party or which may otherwise give rise to liability or violate any law. You may not upload commercial content on the site or use the site to solicit others to join or become members of any other commercial online service or organization.
                        </p>
                        <span id="smaller">
                            Indemnification
                        </span>
                        <p id="bottom">
                            You agree to indemnify, defend, and hold harmless The Sidewalk, its officers, directors, employees, agents, licensors and suppliers (collectively the "Service Providers") from and against all losses, expenses, damages and costs, including reasonable attorney's fees, resulting from any violation of these terms and conditions or any activity related to your account (including negligent or wrongful conduct) by you or any other person accessing the site using your Internet account.
                        </p>
                        <span id="smaller">
                            Third Party Links
                        </span>
                        <p>
                            In an attempt to provide increased value to our visitors, The Sidewalk may choose various third-party websites to link to from its own site. However, even if the third party is affiliated with The Sidewalk, The Sidewalk has no control over these linked sites, all of which have separate privacy and data collection practices, independent of The Sidewalk. The Sidewalk has no responsibility or liability for these independent policies or actions and is not responsible for the privacy practices or the content of such websites. These linked sites are only for your convenience and therefore you access them at your own risk. Nonetheless, The Sidewalk seeks to protect the integrity of its website and the links placed upon it and therefore requests any feedback on not only its site, but for sites it links to as well (including if a specific link does not work). Contact The Sidewalk at info@thesidewalk.co with any feedback regarding third party links.
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>;
};

const DataTable = ({ dataType }) => {
    const dataUsage = [
        {
            category: "Identifiers – e.g. name, email address, physical address, phone number, online identifiers (such as username or social handle), etc.",
            col2: "We may use this data in order to identify you, deliver goods and services to you, market to you, personalize our goods and services to you, associate other data with you, analyze other data we collect, identify other potential customers (e.g. “look alike” customers), develop new goods and services, and for security and fraud prevention.",
            col3: "We obtain this data directly from you when you order goods or services and when you sign up for our services or marketing. We may also obtain your identifiers from affiliates (e.g. when you interact with their sites) and partners (e.g. to verify parts or all of your address).",
        },
        {
            category: "Internet and network activity – e.g. IP address and location data, device ID, browse history, etc.",
            col2: "We may use this data in order to identify you, deliver goods and services to you, market to you, personalize our goods and services to you, associate other data with you, analyze other data we collect, identify other potential customers (e.g. “look alike” customers), develop new goods and services, and for security and fraud prevention.",
            col3: "We obtain this data directly when you interact with our websites, apps, and digital properties. We may also obtain this information indirectly from cookies, tags and other digital tools, from affiliates (e.g. when you interact with their sites) and from partners.",
        },
        {
            category: "Images, audio, video, social media posts, etc.",
            col2: "We may use this data in marketing when you allow us to do so (e.g. post a review), to measure your engagement with our brands (e.g. social posts), to deliver you services, develop new goods and services, and for security and fraud prevention.",
            col3: "We obtain this data directly from you when you post to our websites, apps, or social channels, or tag us on social.",
        },
        {
            category: "Communications with you, e.g. email, chat logs, etc.",
            col2: "We may use this data to provide you goods and services, develop new goods or services, market to you, and for security and fraud prevention.",
            col3: "We obtain this data when you communicate with us.",
        },
        {
            category: "Inferences drawn from other information",
            col2: "We may use inferences drawn from other data to identify you, deliver goods and services to you, market to you, personalize our goods and services to you, associate other data with you, analyze other data we collect, identify other potential customers, develop new goods and services, and for security and fraud prevention.",
            col3: "We may make inferences drawn from other information based on our own observation, analysis, or algorithms. We may also obtain inferences from our affiliates or from partners.",
        },
    ];

    const dataSharing = [
        {
            category: "Identifiers – e.g. name, email address, physical address, phone number, online identifiers (such as username or social handle), etc.",
            col2: "We may share this data with service providers, such as logistics and fulfillment, electronic infrastructure, marketing and advertising, data analytics, and security providers.",
            col3: "We may share this data with third parties, including advertisers, marketing partners, and payment processors, networks, and firms.",
        },
        {
            category: "Internet and network activity – e.g. IP address and location data, device ID, browse history, etc.",
            col2: "We may share this data with service providers, such as electronic infrastructure, marketing and advertising, data analytics, and security providers.",
            col3: "We may share this data with third parties, including advertisers, marketing partners, and payment processors, networks, and firms.",
        },
        {
            category: "Images, audio, video, social media posts, etc.",
            col2: "We may share this data with service providers such as electronic infrastructure, marketing and advertising, data analytics, and security providers. We may share this data publicly where you give us permission (e.g. reviews).",
            col3: "We may share this data with third parties, including advertisers and marketing partners.",
        },
        {
            category: "Communications with you, e.g. email, chat logs, etc.",
            col2: "We may share this data with service providers such as logistics and fulfillment, electronic infrastructure, data analytics, and security providers.",
            col3: "",
        },
        {
            category: "Inferences drawn from other information",
            col2: "We may share this data with service providers such as electronic infrastructure, data analytics, and security providers.",
            col3: "We may share this data with third parties, including advertisers, marketing partners.",
        },
    ];

    const tableData = dataType === "usage" ? dataUsage : dataSharing;
    const columnHeaders =
        dataType === "usage"
            ? ["Data Category", "Purpose", "Sources"]
            : ["Data Category", "Shared With Service Providers", "Shared With Third Parties"];

    return (
        <table className="c-table">
            <thead className="c-thead">
                <tr className="c-thead__tr">
                    {columnHeaders.map((header, index) => (
                        <th key={index} className="c-thead__th" scope="col">
                            {header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {tableData.map((row, index) => (
                    <tr key={index} className="c-table__tr">
                        <td className="c-table__td">{row.category}</td>
                        <td className="c-table__td">{row.col2}</td>
                        <td className="c-table__td">{row.col3}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const styles = {
};


export default About;