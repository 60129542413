import React from "react";
import "../styles/about.css";

const Footer = () => {
  return (
    <div className="aboutComponent">
    <footer className="footer">
      <ul className="footer-links">
        <li className="about-us">
          <a href="/info#about">About Us</a>
        </li>
        <li className="contact-us">
          <a href="/info#contact">Contact Us</a>
        </li>
        <li className="privacy">
          <a href="/info#privacy">Privacy Policy</a>
        </li>
        <li className="terms">
          <a href="/info#terms">Terms of Use</a>
        </li>
      </ul>
    </footer>
    </div>
  );
};

export default Footer;