import React from 'react';
import Logo from '../utils/logo';
import SearchBar from '../utils/search';
import Likes from '../utils/likes';
import { constantStyles } from '../constants/constantStyles';

const centerLogo = true

const HeaderBody = () => {
    return (
        <div style={styles.headerContainerCenteredLogo}>
            <Logo />

            <div style={styles.likesContainer}>
                <Likes />
            </div>

        </div>
    );
};
/* <SearchBar styleOverride={{width: "50%"}}/> */ 
/* remove likes container when switching to old header style */
export default HeaderBody;

const styles = {
    headerContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 0',
        margin: constantStyles.outermostMargin,
    },
    headerContainerCenteredLogo: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: '24px 0',
        margin: constantStyles.outermostMargin,
    },
    likesContainer: {
        position: 'absolute',
        float: 'right',
        right: '24px',
    },
};