import React from 'react';

const Insights = () => {
    return (
        <div>
            <h1>Insights Page</h1>
            <p>This is the insights page content.</p>
        </div>
    );
};

export default Insights;