import { useState, useEffect } from "react";
import { FaRegHeart } from "react-icons/fa";
import { constantStyles } from "../constants/constantStyles";

const endpoint = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : process.env.REACT_APP_LOCAL_SERVER_URL;

const getLikeCountFromStorge = () => {
    const likedProducts = JSON.parse(localStorage.getItem('likedProducts')) || [];
    return likedProducts.length;
};

const Likes = () => {

    const [likeCount, setLikeCount] = useState(getLikeCountFromStorge());
    const fetchData = async () => {
        const currentLikes = JSON.parse(localStorage.getItem('likedProducts')) || [];
        if(currentLikes.length === 0) {
            return;
        }
        try {
            const url = new URL(`${endpoint}api/products`);
            url.searchParams.append('likes', currentLikes);
            
            const response = await fetch(url);
            const result = await response.json();
            
            if (result.error) {
                throw new Error(result.error);
            }

            localStorage.setItem('likedProducts', JSON.stringify(result.products.map(product => product.product_id)));
            setLikeCount(result.products.length);

        } catch (err) {
        }
    };

    useEffect(() => {

        fetchData();

        const updateLikeCount = (event) => {
            if (event.detail.action === 'ADD') {
                setLikeCount((prev) => prev + 1);
            }
            else if (event.detail.action === 'REMOVE') {
                setLikeCount((prev) => prev - 1);
            }
        };

        window.addEventListener("likesUpdated", updateLikeCount);
        return () => window.removeEventListener("likesUpdated", updateLikeCount);
    }, []);
  
    return (
        <div style={styles.likes} onClick={() => window.location.href = '/likes'}>
            <FaRegHeart style={styles.heart} />
            <div style={styles.likeCount}>{likeCount}</div>
        </div>
    );
};

export default Likes;

const styles = {
    heart:{
        fontSize: "24px",
    },
    likeCount: {
        fontFamily: constantStyles.mainFontFamily,
        fontSize: "18px",
        marginLeft: "6px",

    },
    likes: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
};