import React, { useCallback, useEffect, useRef, useState } from 'react';
import Header from '../header/header';
import { constantStyles } from '../constants/constantStyles';
import Filters from './filters';
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';

const endpoint = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : process.env.REACT_APP_LOCAL_SERVER_URL;

const productCardWidth = 300;
const productPadding = 10;

const updateLikes = (productId, setLikeState) => {

    let likedProducts = JSON.parse(localStorage.getItem('likedProducts')) || [];
    let dispatchAction;

    if (likedProducts.includes(productId)) {
        likedProducts = likedProducts.filter(id => id !== productId);
        setLikeState(false);
        dispatchAction = 'REMOVE';
    } else {
        likedProducts.push(productId);
        setLikeState(true);
        dispatchAction = 'ADD';
    }

    localStorage.setItem('likedProducts', JSON.stringify(likedProducts));
    window.dispatchEvent(new CustomEvent("likesUpdated", { detail: { action: dispatchAction } }));
};

const isProductLiked = (productId) => {
    let likedProducts = JSON.parse(localStorage.getItem('likedProducts')) || [];
    return likedProducts.includes(productId);
}

const ProductPage = ({ page_title, initial_filters, likesOnly }) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [productsError, setProductsError] = useState(false);

    const [availableCategories, setAvailableCategories] = useState([]);
    const [availableBrands, setAvailableBrands] = useState([]);
    const [priceRange, setPriceRange] = useState({});
    console.log('window.location.search', window.location.search);
    const [startingParams, setStartingParams] = useState({});
    const [searchFilters, setSearchFilters] = useState({...initial_filters});
    const [readyToFetch, setReadyToFetch] = useState(false);
    useEffect(() => {

        let newStartingParams = new URLSearchParams(window.location.search);
        newStartingParams = Object.fromEntries(newStartingParams.entries());
        for (let key in newStartingParams) {
            if (!Array.isArray(newStartingParams[key])) {
                if(!isNaN(Number(newStartingParams[key]))){
                    newStartingParams[key] = [Number(newStartingParams[key])];
                }else{
                    newStartingParams[key] = [newStartingParams[key]];
                }
            }
          }
          console.log('newStartingParams', newStartingParams);
          setStartingParams(newStartingParams);
          setSearchFilters((prev) => {return {...prev, ...newStartingParams}});
          window.history.replaceState({}, '', window.location.pathname);
          setReadyToFetch(true);
    }, []);

    const [filteredPrice, setFilteredPrice] = useState({});

    const [page, setPage] = useState(0);
    const observer = useRef();

    const showGenders = !('gender' in initial_filters);

    const loadingProductsErrorText = "Sorry we are having trouble finding products, please try again later!";
    const noProductsFound = "We found no products based on your search, please try something different!";
    const noLikesMessage = "It seems like you have not liked any products yet!";

    const fetchData = async (searchFilters, filteredPrice, page) => {
        if (!readyToFetch) return;
        try {
            if (likesOnly && !localStorage.getItem('likedProducts')) {
                setLoading(false);
                return;
            }
            const currentUrl = new URL(window.location.href);
            const currentParams = new URLSearchParams(currentUrl.search);


            const paramsObject = {};

            // Loop through each search parameter
            currentParams.forEach((value, key) => {
                // If the key already exists in the object, push the value into an array
                if (paramsObject[key]) {
                    paramsObject[key] = Array.isArray(paramsObject[key])
                        ? [...paramsObject[key], value] // If it's already an array, push the new value
                        : [paramsObject[key], value];  // Convert to array if it's not already
                } else {
                    // If the key doesn't exist, just assign the value
                    paramsObject[key] = value;
                }
            });

            const url = new URL(`${endpoint}api/products`);
            console.log('url.searchParams', Object.fromEntries(url.searchParams));
            console.log('paramsObject', paramsObject);
            console.log('searchFilters', searchFilters);
            Object.keys(paramsObject).forEach(key => url.searchParams.append(key, paramsObject[key]));
            Object.keys(searchFilters).forEach(key => url.searchParams.append(key, searchFilters[key]));
            Object.keys(filteredPrice).forEach(key => url.searchParams.append(key, filteredPrice[key]));
            url.searchParams.append('page', page);

            likesOnly && url.searchParams.append('likes', JSON.parse(localStorage.getItem('likedProducts')) || []);
            console.log('url.searchParams', Object.fromEntries(url.searchParams));
            const response = await fetch(url);
            const result = await response.json();

            if (result.error) {
                throw new Error(result.error);
            }
            if (page === 0) {
                setData(result.products);
            } else {
                setData((prevProducts) => [...prevProducts, ...result.products]);
            }
            setAvailableCategories([result.categories]);
            setPriceRange(result.price_range);
            setAvailableBrands([result.brands]);

            setLoading(false);

        } catch (err) {
            setProductsError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        const seenNoticeBefore = localStorage.getItem('manuallyClosedNotice');
        if (!seenNoticeBefore) {
            toast.info('We may make a small commission when you buy from one of our affiliates!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                onClose: (removedByUser) => { if (removedByUser) { localStorage.setItem('manuallyClosedNotice', true); } }
            });
        }
    }, []);

    useEffect(() => {
        setPage(0);
        fetchData(searchFilters, filteredPrice, 0);
    }, [searchFilters, filteredPrice]);

    useEffect(() => {
        if (page > 0) {
            fetchData(searchFilters, filteredPrice, page);
        }
    }, [page]);

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading]
    );

    const ProductContents = ({ item }) => {

        const { product_id, product_title, price } = item;

        const [like, setLike] = useState(isProductLiked(product_id));

        return (<div style={styles.productContents}>
            <div style={styles.leftProductContents}>
                <div style={styles.title}>{product_title}</div>
                <div style={styles.price}>{price}</div>
            </div>
            <div style={styles.rightProductContents}>
                <div style={styles.like} onClick={(e) => {
                    updateLikes(product_id, setLike);
                    e.stopPropagation();
                }}>
                    {like ? <FaHeart style={{ ...styles.heart, ...styles.colorHeart }} /> : <FaRegHeart style={styles.heart} />}</div>
            </div>
        </div>);
    };

    const products = (data) => {

        if (loading) return <div style={styles.proudctsIssue}>Loading...</div>;
        if (productsError) return <div style={styles.proudctsIssue}>{loadingProductsErrorText}</div>;
        if (data.length === 0 && likesOnly) return <div style={styles.proudctsIssue}>{noLikesMessage}</div>;
        if (data.length === 0) return <div style={styles.proudctsIssue}>{noProductsFound}</div>;
        return <div style={styles.productsContainer}>
            {data.map((item, index) => {

                const { product_url, product_id, image_url, product_title, price } = item;

                return (
                    <div style={styles.productContainer} key={product_id}
                        onClick={() => window.open(product_url, "_blank")}
                        ref={index === data.length - 1 ? lastPostElementRef : null}>
                        <div style={styles.imageContainer}>
                            <img style={styles.image} src={image_url} alt={product_title} />
                        </div>
                        <ProductContents item={item} />
                    </div>
                );
            }
            )}
        </div>;
    }

    return (
        <div>
            <Header />
            <div style={styles.pageTitle}>{page_title}</div>
            <div style={styles.productFilterContainer}>
                <Filters
                    availableCategories={availableCategories} setSearchFilters={setSearchFilters}
                    priceRange={priceRange} setFilteredPrice={setFilteredPrice} filteredPrice={filteredPrice}
                    availableBrands={availableBrands}
                    showGenders={showGenders}
                    startingParams={startingParams}
                />
                {products(data)}
            </div>
            <ToastContainer
                position="bottom-left"
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                draggable
                theme="light"
            />
        </div>
    );
};

export default ProductPage;

const styles = {
    pageTitle: {
        fontFamily: constantStyles.mainFontFamily,
        margin: constantStyles.outermostMargin,
        marginTop: '20px',
        marginBottom: '20px',
        fontSize: '24px',
    },
    image: {
        display: 'block',
        height: 'auto',
        width: '100%',
    },
    imageContainer: {
    },
    productContents: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '10px 0',
    },
    rightProductContents: {
        display: 'flex',
        marginLeft: '7%',
    },
    leftProductContents: {
        display: 'flex',
        flexDirection: 'column',
    },
    productContainer: {
        width: productCardWidth + 'px',
        padding: productPadding + 'px',
        cursor: 'pointer',
        margin: '0 auto',
    },
    productsContainer: {
        display: 'inline-grid',
        flexGrow: '1',
        gridTemplateColumns: 'repeat(auto-fit, minmax(' + (productCardWidth + 2 * productPadding) + 'px, 1fr))',
    },
    productFilterContainer: {
        display: 'flex',
    },
    proudctsIssue: {
        margin: 'auto',
    },
    title: {
        maxHeight: '2em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: constantStyles.mainFontFamily,
    },
    colorHeart: {
        color: '#ff0000b8',
    },
    heart: {
        fontSize: "24px",
    },
};