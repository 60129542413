import React from 'react';
import { constantStyles } from '../constants/constantStyles';

const Logo = () => {
    return (
        <div style={{ ...styles.logoGeneral, ...styles.longLogo }} onClick={() => window.location.href = '/'}>
            <div style={{ ...styles.align, ...styles.the }}>The</div>
            <div style={{ ...styles.align, ...styles.sidewalk }}>Sidewalk</div>
            <div style={{ ...styles.align, ...styles.co }}>Co.</div>
        </div>
    );
}

export default Logo;

const styles = {
    longLogo: { display: 'flex' },
    logoGeneral: {
        textTransform: 'uppercase',
        fontFamily: constantStyles.mainFontFamily,
        color: constantStyles.mainDarkGreen,
        cursor: 'pointer',
    },
    align: {
        display: 'flex',
    },
    the: {
        fontSize: '16px',
    },
    sidewalk: {
        fontSize: '30px',
    },
    co: {
        fontSize: '14px',
        fontWeight: 'lighter',
        justifyContent: 'flex-end', // remove to go back to old logo
        flexDirection: 'column', // remove to go back to old logo
    },
};