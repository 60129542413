import React, { useCallback, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { constantStyles } from '../constants/constantStyles';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const SwipeHeader = () => {

    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <div style={styles.outerWrapper}>
            <div style={styles.innerWrapper}>
                <Swiper
                    ref={sliderRef}
                    modules={[Navigation, Autoplay]}
                    loop
                    speed={1000} 
                    autoplay={{ delay: 3000 }}
                >
                    <SwiperSlide className='text-swipe'>
                        <div style={styles.textSwipe}><span>new spring dresses! | </span>
                            <a href='/women?categories=19' style={styles.linkSwipe}>shop now</a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='text-swipe'>
                        <div style={styles.textSwipe}><span>shop our newest brand | </span>
                            <a href='/women?brands=3' style={styles.linkSwipe}>Princess Polly</a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='text-swipe'>
                    <div style={styles.textSwipe}><span>browse all our featured styles </span>
                            <a href='/featured' style={styles.linkSwipe}>here</a>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div style={{...styles.arrow, ...{left: '-15px'}}}>
                    <IoIosArrowBack onClick={handlePrev} style={{fill: 'rgb(167 167 167)'}}/>
                </div>
                <div style={{...styles.arrow, ...{right: '15px'}}}>
                    <IoIosArrowForward onClick={handleNext} style={{fill: 'rgb(167 167 167)'}}/>
                </div>

            </div>
        </div>
    );
};

const styles = {
    outerWrapper: {
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: constantStyles.mainDarkGreen,
        background: constantStyles.mainDarkGreen,
    },
    innerWrapper: {
        width: '35%',
        margin: 'auto',
        display: 'flex',
        height: '50px',
        position: 'relative'
    },
    textSwipe: {
        color: 'rgb(167 167 167)',
    },
    linkSwipe: {
        color: 'rgb(167 167 167)',
    },
    arrow: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: '1',
    },
};

export default SwipeHeader;